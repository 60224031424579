/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/38be85");

@font-face {font-family: 'Gilroy-SemiBold';src: url('fonts/38BE85_0_0.eot');src: url('fonts/38BE85_0_0.eot?#iefix') format('embedded-opentype'),url('fonts/38BE85_0_0.woff2') format('woff2'),url('fonts/38BE85_0_0.woff') format('woff'),url('fonts/38BE85_0_0.ttf') format('truetype');}  
@font-face {font-family: 'Gilroy-Regular';src: url('fonts/38BE85_1_0.eot');src: url('fonts/38BE85_1_0.eot?#iefix') format('embedded-opentype'),url('fonts/38BE85_1_0.woff2') format('woff2'),url('fonts/38BE85_1_0.woff') format('woff'),url('fonts/38BE85_1_0.ttf') format('truetype');}  
@font-face {font-family: 'Gilroy-Medium';src: url('fonts/38BE85_2_0.eot');src: url('fonts/38BE85_2_0.eot?#iefix') format('embedded-opentype'),url('fonts/38BE85_2_0.woff2') format('woff2'),url('fonts/38BE85_2_0.woff') format('woff'),url('fonts/38BE85_2_0.ttf') format('truetype');}
@font-face {font-family: 'Gilroy-Light';src: url('fonts/38BE85_3_0.eot');src: url('fonts/38BE85_3_0.eot?#iefix') format('embedded-opentype'),url('fonts/38BE85_3_0.woff2') format('woff2'),url('fonts/38BE85_3_0.woff') format('woff'),url('fonts/38BE85_3_0.ttf') format('truetype');}
@font-face {font-family: 'Gilroy-ExtraBold';src: url('fonts/38BE85_4_0.eot');src: url('fonts/38BE85_4_0.eot?#iefix') format('embedded-opentype'),url('fonts/38BE85_4_0.woff2') format('woff2'),url('fonts/38BE85_4_0.woff') format('woff'),url('fonts/38BE85_4_0.ttf') format('truetype');}
@font-face {font-family: 'Gilroy-Black';src: url('fonts/38BE85_5_0.eot');src: url('fonts/38BE85_5_0.eot?#iefix') format('embedded-opentype'),url('fonts/38BE85_5_0.woff2') format('woff2'),url('fonts/38BE85_5_0.woff') format('woff'),url('fonts/38BE85_5_0.ttf') format('truetype');}
 
// @import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');

// @font-face {
//   font-family: 'Walsheim';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: local('Walsheim'), local('Walsheim-Regular'), url(./fonts/gt-walsheim-regular.ttf) format('truetype');
// }


// RoundPoint blue
$blue: #40B4E2;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
// $font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// $font-family-base:            $font-family-sans-serif !default;

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Gilroy-Medium", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

// Assumes the browser default, typically `16px`
$font-size-base: 0.8rem;

// 
$link-blue:    #007bff;

$link-color:            $link-blue;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;


// Paragraphs
$paragraph-margin-bottom: 2rem;

// 
// $body-bg: #fff;
// $body-color: #23282c;

// 
$theme-colors: (
  "primary": $blue,
  "danger": #ff4136
);


@import "./node_modules/bootstrap/scss/bootstrap";


// Additional changes

.btn-primary {
  color: $white;
}

