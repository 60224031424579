
footer {
  margin-top: 80px;
  padding: 70px 70px 100px 125px;

  background-color: #353b3e;
  color: #fff;
}

.disclaimer {
  margin-left: 150px;
}

.disclaimer-text {
  color: #4c5969;
  font-size: 12px;
  line-height: 20px;

  padding-left: 15px;
  padding-right: 15px;
}

.footer-divider {
  margin-bottom: 3rem;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
    box-sizing: content-box;
    height: 0;
}

.footer-content-container {
  padding: 10px;
}

.footer-content {
  font-family: 'Gilroy-Light';
  font-size: 1rem;
}

.footer-content a {
  color: #fff;
}
.footer-content a:hover {
  color: #fff;
}

.footer-header {
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.4;
}

.footer-list {
  list-style: none;
  line-height: 2.2;
  padding-left: 0;
}

.footer-list a {
  font-family: 'Gilroy-SemiBold';
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

.footer-list a:hover {
  color: #40B4E2;
  text-decoration: none;
}
