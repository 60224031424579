
header {
  position: fixed;
  padding-bottom: 22px;
  width: 100%;
  top: 0;
  z-index: 2;
  background-color: #fff;
}

.nav-item {
  border-right: 1px solid #dee2e6 !important;
}

.nav-link {
  color: rgb(90, 90, 90);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.1px;
}

.nav-pay-now {
  border-right: 0px solid #dee2e6 !important;
}

.nav-pay-now-link {
  color: rgb(108, 184, 216);
}
