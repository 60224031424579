
#left-nav {
  position: fixed;
  display: block;
  top: 96px;
  width: 220px;
}

.left-nav-menu {
  list-style: none;
}

.left-nav-item {
  color: rgb(90, 90, 90);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.1px;
  padding-bottom: 10px;
  margin-bottom: 32px;
  border-bottom: 1px solid #dee2e6 !important;
}

.left-nav-item:first-child {
  line-height: 28px;
}

.left-nav-item a {
  color: rgb(90, 90, 90);
  text-decoration: none;
}

.left-nav-item a:hover {
  color: #337ab7;
  text-decoration: none;
}



